.table {
    font-size: 15px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: #121212;
    width: 61px;
    height: 24px;
    gap: 0px;
    opacity: 0px;
  }

  .pagination-custom {
    background-color: #c42072 !important;
    color: white !important;
    margin: 0 5px !important;
    border: none !important;
  }

  