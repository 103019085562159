
  .cardstitle{
      color: #A0A0A0;
      font-weight: 400;
      font-size: 14px;

  }

  .cardsnumber {
    color: #E0E0E0;
    font-size: 24px;
    font-weight: 700;
  }
 
  .col-lg-3{
    background-color: #2D2F3A;
  }

  

/* Define slide-up animation */
@keyframes slideUp {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Apply the animation to each card */
.animate-slide-up {
  animation: slideUp 0.6s ease-out;
  animation-fill-mode: forwards;
  opacity: 0;
}




  .chartparent{
    width: 150px;
     left: 15px;
     position: relative;
    height: 130px;
    background-color: #ffffff;
   
  }


  @media (max-width:"700px") {
    #transaction{
      margin-top: 40px;
    }
    
  }

