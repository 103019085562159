.navpage{
   
    font-size: 20px;
    font-weight: 700;
    /* line-height: 30px; */
    /* text-align: left; */
    /* top: 19px; */
    /* position: relative; */
}

/* Sidebar.module.css */

.sidebars {
    display: flex;
    flex-direction: column; /* Layout direction */
    /* background: #FFFFFF; */
  }


  @media (max-width :500px) {
     .side{
      width: 120px;
     }
    
  }
  
  .dashboard {
    width: 280px;
    height: 50px;
    padding: 20px 0; /* Top and bottom padding */
    gap: 10px;
    opacity: 1; /* Set opacity to make it visible */
    padding: 10px; /* Add some padding for better click area */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
    display: flex; /* Flexbox layout */
    align-items: center; /* Center items vertically */
  }
  
  .dashboard img {
    margin-right: 10px; /* Add margin to create space between icon and text */
  }
  

  
  .dashboard:hover {
    /* background-color: rgba(196, 32, 114, 0.2); Use rgba for transparency */
  }
  
  /* Optional: If you want the hover color to apply only when an item is active */
  .menu-item-active {
    /* background-color: rgba(196, 32, 114, 0.2); Add a background color for active item */
  }
  
  .headerContainer {
    padding: 0 20px; /* Adjust as needed */
  }
  
  .notify {
    cursor: pointer;
  }
  
  .profileButton {
    display: flex;
    align-items: center;
  }
  
  .dropdown {
    position: relative;
  }
  
 
  .notify {
    cursor: pointer;
  }
  
  .profileButton {
    display: flex;
    align-items: center;
  }
  
  .dropdown {
    position: relative;
  }
  

 /* navbar.module.css */

/* Custom styles for Ant Design Menu */
.customMenu .ant-menu-item {
    background-color: transparent !important; /* Active item background color */
}

.customMenu .ant-menu-item:hover {
    background-color: rgba(255, 255, 255, 0.1); /* optional hover color */
  }

  