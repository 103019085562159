/* Signup container fade-in effect */
.signup-container {
    background: #1E1E2E;
    animation: fadeInScale 1.5s ease-in-out;
  }
  
  @keyframes fadeInScale {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  /* Signup box animation */
  .signup-box {
    background: #2D2F3A;
    max-width: 400px;
    width: 100%;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
    animation: slideUp 1.2s ease-out;
  }
  
  @keyframes slideUp {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Button hover animation */
  .signup-button {
    background: #5865F2;
    border-color: #5865F2;
    transition: all 0.3s ease-in-out;
  }
  
  .signup-button:hover {
    background: #4752C4;
    transform: scale(1.05);
  }
  
  /* Form fade-in animation */
  .signup-form {
    animation: fadeInUp 1s ease-in-out;
  }
  
  /* Heading bounce effect */
  .heading {
    animation: bounceIn 1s ease-in-out;
  }
  
  /* Input field fade-in up animation */
  .input-field {
    animation: fadeInUp 1s ease-out;
  }
  
  /* Submit button pulsing effect */
  .submit-button {
    animation: pulse 1.5s infinite;
  }
  
  /* Error message fade-in up animation */
  .error {
    animation: fadeInUp 1s ease-out;
  }
  
  /* Keyframes */
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes bounceIn {
    0% {
      transform: scale(0.3);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes pulse {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
  }
  