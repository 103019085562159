/* login.module.css */

.container {
    background-color: #1E1E2E;
    transition: all 0.6s ease-out;
  }
  
  .row {
    display: flex;
    width: 100%;
  }
  
  .reverse {
    flex-direction: row-reverse;
  }
  
  .imageSection {
    background-image: url('../Assets/Login_img.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
  }
  
  .form {
    background-color: #2D2F3A;
    color: #E4E4E7;
    border-radius: 8px;
    animation: slideIn 1s ease-out;
  }
  
  .input {
    background-color: transparent;
    border-color: #555;
    color: #E4E4E7;
    border-radius: 50px;
  }
  
  .loginButton {
    background-color: #5865F2;
    border-color: #5865F2;
    transition: background-color 0.3s ease;
  }
  
  .loginButton:hover {
    background-color: #4752e6;
  }
  
  .error {
    animation: shakeError 0.5s ease-out;
  }
  
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(50px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes shakeError {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(-5px);
    }
    50% {
      transform: translateX(5px);
    }
    75% {
      transform: translateX(-5px);
    }
    100% {
      transform: translateX(0);
    }
  }
  



  /* Fade-in animation for Image Section */
.imageSection {
    background-image: url("../Assets/WhatsApp Image 2024-11-22 at 18.41.55_baad1a49.jpg");
    background-size: cover;
    background-position: center;
    animation: fadeIn 1.5s ease-in-out;
  }
  
  /* Slide-in animation for the form section */
  .slideInFromRight {
    animation: slideInRight 1s ease-out;
  }
  
  /* Error alert animation */
  .fadeInError {
    animation: fadeIn 1s ease-in-out;
  }
  
  /* Zoom-in animation for the login button */
  .zoomInAnimation {
    animation: zoomIn 0.6s ease-out;
  }
  
  /* Keyframes for different animations */
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes slideInRight {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes zoomIn {
    0% {
      transform: scale(0.8);
      opacity: 0.5;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  /* Additional styling for the error message */
  .error {
    text-align: center;
    margin-top: 10px;
  }
  
  /* Ensure smooth transitions on input */
  .input {
    transition: all 0.3s ease;
  }
  
  .input:focus {
    border-color: #5cb85c;
    box-shadow: 0 0 10px rgba(92, 184, 92, 0.6);
  }