/* Default styles for buttons */
.btn-custom {
    background-color: rgba(37, 40, 54, 1); /* Default background color */
    color: #EEEEEE; /* Font color */
    border: 1px solid transparent; /* Transparent border */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
  }
  
  /* When the radio button is selected */
  .btn-check:checked + .btn-custom {
    background-color: rgba(58, 63, 81, 1); /* Selected background color */
    color: #EEEEEE; /* Keep the text color same */
    border-color: transparent; /* Keep the border transparent */
  }

  /* Make radio button background transparent */
.form-check-input {
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
  }
  
  /* Optional: Customize the checked state (if needed) */
  .form-check-input:checked {
    background-color: transparent !important;
    border-color: transparent !important;
  }
  
  .cardstitle {
    font-weight: bold;
    text-transform: uppercase;
    color: #EEEEEE;
  }

  .cardstitles {
    text-transform: uppercase;
    color: #A0A0A0;
  }

  .cardsnumber {
    font-size: 1rem;
    color: #E0E0E0;
  }
  